import axios from "axios";
import { constructHeader, formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";

export async function fetchProductMapping(): Promise<any> {
  const sessionMapping = sessionStorage.getItem("productMapping");
  if (sessionMapping) {
    console.debug("Product mapping found in session storage.");
    return JSON.parse(sessionMapping);
  }

  console.debug("No product mapping found. Fetching from server...");
  try {
    const headers = await constructHeader();
    const productMappingUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.getProductMapping
    );
    const response = await axios.get(productMappingUrl, {headers});
    sessionStorage.setItem("productMapping", JSON.stringify(response.data));
    console.debug("Product mapping fetched and stored in session storage.");
    return response.data;
  } catch (error) {
    console.error("Error fetching product mapping:", error);
    throw error;
  }
}

export async function fetchHistoryTemplates(): Promise<any> {
  try {
    const headers = await constructHeader();
    const historyTemplatesUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.getHistoryTemplates
    );
    const response = await axios.get(historyTemplatesUrl, {headers});
    console.debug("History templates fetched successfully.");
    return response.data;
  } catch (error) {
    console.error("Error fetching history templates:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
}