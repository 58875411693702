import { useQuery } from '@tanstack/react-query';
import { fetchProductMapping } from '../api/mongoEndpoints';

export type DisplayProperties = {
  backgroundColor: string;
  textColor: string;
  displayText: string;
};

export type ProductMappingItem = {
  _id: string;
  productId: string;
  displayProperties: DisplayProperties;
};

export const QUERY_KEYS = {
  productMapping: ['productMapping'] as const,
} as const;

export const useProductMapping = () => {
  return useQuery<ProductMappingItem[]>({
    queryKey: QUERY_KEYS.productMapping,
    queryFn: fetchProductMapping,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    select: (data) => data.sort((a, b) => a.productId.localeCompare(b.productId)),
  });
};

// Utility function to find a product by ID
export const findProductMetaById = (products: ProductMappingItem[] | undefined, id: string) => {
  return products?.find(p => p.productId === id)?.displayProperties;
};
