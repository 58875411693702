import { useState } from "react";
import axios from "axios";
import { constructHeader, formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import { ICompetitorPriceData } from "../models/ICompetitorPriceData";

const useElasticSearchQuery = (
  size: number,
  distance: string,
  latitude: number,
  longitude: number
) => {
  const [data, setData] = useState<ICompetitorPriceData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const performQuery = async () => {
    setLoading(true);
    setError(null);

    try {
      const headers = await constructHeader();
      const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceViewMaster.name,
        constants.urlConstants.priceGenPriceViewMaster.getStoresFromES
      );

      const response = await axios.post(
        serviceUrl,
        {
          size: size,
          distance: distance,
          location: {
            lat: latitude,
            lon: longitude,
          },
        },
        { headers }
      );

      setData(response.data.hits.hits.map((hit: any) => hit._source));
      setLoading(false);
      return response.data;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { data, loading, error, performQuery };
};

export default useElasticSearchQuery;
