import React, { useContext, useState, useMemo } from "react";
import { Tooltip } from "@mui/material";
import { PriceDataContext } from "../../context/PriceDataContext";
import PriceDataModel from "../../models/priceView/PriceData.model";
import "./ActionTableCell.css";
import { getImagePath } from "../../util/util";
import { APPROVED, PENDING, REJECTED } from "../../util/constants/stringConstants";

type ActionMode = "approve" | "deny";

interface ActionTableCellProps {
  dataItem: PriceDataModel;
  mode: ActionMode;
}

interface ButtonState {
  isDisabled: boolean;
  isActive: boolean;
  tooltipText: string;
  iconName: string;
}

type IconNames = 
  | "deny" | "denyRed" | "denyDisabled"
  | "checkmark" | "checkmarkGreen" | "checkmarkDisabled";

interface BaseActionConfig {
  action: typeof APPROVED | typeof REJECTED;
  baseTooltip: string;
  undoTooltip: string;
  icons: {
    base: IconNames;
    hover: IconNames;
    active: IconNames;
    disabled: IconNames;
  };
  style: {
    backgroundColor: string;
  };
  className: string;
}

interface ApproveConfig extends BaseActionConfig {
  manualTooltip: string;
}

interface ActionConfigs {
  deny: BaseActionConfig;
  approve: ApproveConfig;
}

const actionConfig: ActionConfigs = {
  deny: {
    action: REJECTED,
    baseTooltip: "Deny",
    undoTooltip: "Undo Reject",
    icons: {
      base: "deny",
      hover: "denyRed",
      active: "denyRed",
      disabled: "denyDisabled",
    },
    style: {
      backgroundColor: "#FFDFDF",
    },
    className: "status-rejected",
  },
  approve: {
    action: APPROVED,
    baseTooltip: "Approve",
    undoTooltip: "Undo Approve",
    manualTooltip: "Revert Manual Price",
    icons: {
      base: "checkmark",
      hover: "checkmarkGreen",
      active: "checkmarkGreen",
      disabled: "checkmarkDisabled",
    },
    style: {
      backgroundColor: "#CAFFCA",
    },
    className: "status-approved",
  },
};

function getButtonState(
  dataItem: PriceDataModel,
  mode: ActionMode,
  isHovered: boolean
): ButtonState {
  const config = actionConfig[mode];
  const isPending = dataItem.priceData.latestPriceGenPriceStatus === PENDING;
  const isApproved = dataItem.status === APPROVED;
  
  const isDisabled = !isPending && (!isApproved || (isApproved && mode === "deny"));
  const isActive = !isDisabled && dataItem.status === config.action;

  // Determine tooltip
  let tooltipText = config.baseTooltip;
  if (mode === "approve" && dataItem.isManualPriceSet) {
    tooltipText = (config as ApproveConfig).manualTooltip;
  } else if (
    (isApproved && mode === "approve" && !dataItem.isManualPriceSet) ||
    (dataItem.status === REJECTED && mode === "deny")
  ) {
    tooltipText = config.undoTooltip;
  }

  // Determine icon
  let iconName = config.icons.base;
  if (isDisabled) {
    iconName = config.icons.disabled;
  } else if (isActive) {
    iconName = config.icons.active;
  } else if (isHovered) {
    iconName = config.icons.hover;
  }

  return { isDisabled, isActive, tooltipText, iconName };
}

export const ActionTableCell: React.FC<ActionTableCellProps> = ({
  dataItem,
  mode,
}) => {
  const { changeProposedPriceStatus } = useContext(PriceDataContext);
  const [isHovered, setIsHovered] = useState(false);

  const state = useMemo(
    () => getButtonState(dataItem, mode, isHovered),
    [dataItem, mode, isHovered]
  );

  const config = actionConfig[mode];

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!state.isDisabled || dataItem.isManualPriceSet) {
      changeProposedPriceStatus(config.action, dataItem.productId);
    }
  };

  return (
    <td
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`action-td bordered-cell ${
        state.isActive ? config.className : ""
      } ${state.isDisabled ? "disabled" : ""}`}
      style={state.isActive ? config.style : {}}
      onClick={handleClick}
    >
      <Tooltip title={state.isDisabled ? "" : state.tooltipText} arrow>
        <span>
          <button 
            className="action-btn" 
            onClick={handleClick} 
            disabled={state.isDisabled}
          >
            <img 
              src={getImagePath(state.iconName)} 
              alt={config.baseTooltip} 
            />
          </button>
        </span>
      </Tooltip>
    </td>
  );
};
